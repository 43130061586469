import React from "react";

import styled from "styled-components";

const Wrapper = styled.span`
  .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
`;

const AllyText = ({ children, },) => (
  <Wrapper className="screen-reader-text">{children}</Wrapper>
);

export default AllyText;
