import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 1rem;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  font-family: sans-serif;

  .flipcard-title {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    color: #e2001a;
    font-weight: 300;
    margin-top: 0;
  }

  .flipcard-content {
    line-height: 1.5;
    color: #666;
  }

  .flipcard-link-container {
    margin-top: 0.5rem;
    a {
      text-decoration: none;
      margin: 1rem 0;
      color: #e2001a;
      border-bottom: 1px solid rgba(226, 0, 26, 0.15);
      position: relative;
      transition: color 0.2s ease-in, border-color 0.2s ease-in;
      &:hover {
        border-bottom: 1px solid #e2001a;
      }
    }
  }

  .flipcard-icon {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  .flipcard-icon::after {
    background-image: url(../../assets/royal-canin.sprite-brand.svg);
    background-position: 66.67% 25%;
    content: "";
    display: inline-block;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    overflow: hidden;
    transform-origin: 50% 50%;
    position: relative;
  }

  @media (min-width: 769px) {
    padding: 2rem;

    .flipcard-title {
      font-size: 1.25rem;
      line-height: 2rem;
      margin-bottom: 2rem;
    }

    .flipcard-link-container {
      margin-top: 2rem;
    }
    .flipcard-icon {
      margin-bottom: 2rem;
    }
  }
`;

const FlipCard = ({ title, content, link }) => (
  <Wrapper>
    {title ? (
      <p className='flipcard-title'>{title}</p>
    ) : (
      <span className='flipcard-icon' />
    )}
    {content && <p className='flipcard-content'>{content}</p>}
    {link && (
      <div className='flipcard-link-container'>
        <a className='styled-link--cta' href={link?.href}>
          {link?.title}
        </a>
      </div>
    )}
  </Wrapper>
);

export default FlipCard;
