export default class BaseComponent {
    constructor(el) {
        this.el = el;
        this.isInited = false;
        this.listenersList = [];
    }

    init() {
        throw new Error('You should define init method');
    }

    destroy() {
        this.removeListeners();
        this.onDestroy();
    }

    onDestroy() {
    }

    setVariables() {
        throw new Error('You should define setVariables method');
    }

    addListeners() {
        throw new Error('You should define addListeners method');
    }

    addListener(elem, eventName, eventCallback) {
        if (!elem || typeof elem.addEventListener !== 'function') return;

        elem.addEventListener(eventName, eventCallback);
        this.listenersList.push({
            elem,
            eventName,
            eventCallback
        });
    }

    removeListeners() {
        this.listenersList.forEach((listener) => {
            const { elem, eventName, eventCallback } = listener;

            elem.removeEventListener(eventName, eventCallback);
        });

        this.listenersList = [];
    }
}
