import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useClickOutside } from "../utils/hooks/useClickOutside";
import { KEY_DOWN, KEY_ENTER, KEY_SPACE, KEY_UP } from "../utils/keybord";

import {
  Wrapper,
  ItemComponentWrapper,
  PlaceholderComponentWrapper,
} from "./styled";

const Dropdown = ({
  label,
  value,
  options,
  id,
  labelledBy,
  onChange,
  onFocus,
  ItemComponent,
  PlaceholderComponent,
  filterFunc,
  isBlock,
  isDisabled,
  className,
  hasPreselectedValue = false,
  resetFilter = false,
  hasArrow = false,
  ...props
}) => {
  const searchInputRef = React.createRef();

  const [filter, setFilter] = useState("");

  const [$options, set$Options] = useState([]);

  useEffect(() => {
    if (resetFilter) setFilter("");
  }, [resetFilter]);

  useEffect(() => {
    if (typeof filterFunc === "function") {
      set$Options(options.filter((option) => filterFunc(filter, option)));
    } else {
      set$Options(options);
    }
  }, [options, filterFunc, filter]);

  const [is, setIs] = useState({
    listOpen: false,
    inputOpen: false,
  });

  const set = {
    isListOpen: (val) => {
      setIs((oldState) => ({
        ...oldState,
        listOpen: val,
      }));
    },
    isInputOpen: (val) => {
      setIs((oldState) => ({
        ...oldState,
        inputOpen: val,
      }));
    },
  };

  const buttonRef = useRef();
  const selectedRef = useRef();
  const dropdownRef = useRef();

  useClickOutside(dropdownRef, () => {
    set.isListOpen(false);
    set.isInputOpen(false);
  });

  useEffect(() => {
    if (is.listOpen) {
      selectedRef.current?.focus();
    }
  }, [is.listOpen, selectedRef, value]);

  useEffect(() => {
    if (
      is.listOpen &&
      typeof filterFunc === "function" &&
      searchInputRef.current
    ) {
      searchInputRef.current.focus();
    }
  }, [is.listOpen]);

  useEffect(() => {
    if (filter.length >= 2) {
      set.isListOpen(true);
    } else {
      set.isListOpen(false);
    }
  }, [filter]);

  /**
   ** [https://stackoverflow.com/a/23724848]
   ** Since the input is not the element that receive the user click or touch events,
   ** We need to explicitly give him focus when on mobile environments.
   */
  useEffect(() => {
    if (is.inputOpen && searchInputRef?.current) {
      searchInputRef.current.focus();
    }
  }, [is.inputOpen, searchInputRef]);

  if (options.length === 0) return null;

  const buttonMouseDown = () => {
    if (typeof filterFunc === "function") {
      set.isInputOpen(true);
    } else {
      if (is.listOpen) buttonRef.current.focus();
      set.isListOpen(!is.listOpen);
    }
  };

  const buttonKeyDown = (e) => {
    if (e.key === KEY_ENTER || e.key === KEY_SPACE) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const buttonKeyUp = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.key === KEY_ENTER || e.key === KEY_SPACE) {
      set.isListOpen(!is.listOpen);
    }
  };

  const flyoutBlur = (e) => {
    const focusMovingTo = e.relatedTarget || e.target;

    if (!e.currentTarget.contains(focusMovingTo)) {
      set.isListOpen(false);
    }
  };

  const buttonFocus = () => {
    if (onFocus) onFocus();
  };

  const listItemKeyDown = (e) => {
    if (e.key === KEY_UP || e.key === KEY_DOWN || e.key === KEY_SPACE) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const listItemKeyUp = (option, index) => (e) => {
    if (e.key === KEY_UP) {
      if (index > 0) {
        onChange(options[index - 1]);
      }
    } else if (e.key === KEY_DOWN) {
      if (index < options.length - 1) {
        onChange(options[index + 1]);
      }
    } else if (e.key === KEY_ENTER || e.key === KEY_SPACE) {
      e.preventDefault();
      e.stopPropagation();

      onChange(option);
      set.isListOpen(false);
      buttonRef.current.focus();
    }
  };

  const setValue = (newValue) => {
    onChange(newValue);
    set.isListOpen(false);
    set.isInputOpen(false);
  };

  return (
    <Wrapper
      className={classNames(is.listOpen && "open", className)}
      ref={dropdownRef}
      {...props}
      isSearch={filterFunc}
      hasValue={!!value}
      isBlock={isBlock}
      isDisabled={isDisabled}
      hasPreselectedValue={hasPreselectedValue}
      hasArrow={hasArrow}
      isListOpen={is.listOpen}
      isInputOpen={is.inputOpen}
    >
      {label && <div className="label">{label}</div>}
      <div className="main">
        <div className="handler">
          {filterFunc && (
            <div className="search-icon">
              <div className="inner" />
            </div>
          )}
          {(!filterFunc || !is.inputOpen) && (
            <button
              className="handler"
              id={id ? `${id}-button` : undefined}
              aria-haspopup="listbox"
              aria-expanded={is.listOpen}
              aria-labelledby={labelledBy}
              ref={buttonRef}
              tabIndex={0}
              onMouseDown={buttonMouseDown}
              onKeyDown={buttonKeyDown}
              onKeyUp={buttonKeyUp}
              onFocus={buttonFocus}
              type="button"
              disabled={isDisabled}
            >
              <PlaceholderComponent value={value} />
            </button>
          )}
          {filterFunc && is.inputOpen && (
            <input
              ref={searchInputRef}
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            />
          )}
        </div>
      </div>

      <ul
        className={classNames("list", is.listOpen && "open")}
        role="listbox"
        tabIndex={-1}
        onBlur={!filterFunc ? flyoutBlur : undefined}
      >
        {$options.map((option, index) => (
          <li
            className={classNames("item", option === value && "selected")}
            key={option}
            tabIndex={-1}
            role="option"
            onKeyDown={listItemKeyDown}
            onKeyUp={listItemKeyUp(option, index)}
            onClick={() => setValue(option)}
            aria-selected={option === value}
            ref={option === value ? selectedRef : null}
          >
            <ItemComponent value={option} filter={filter} />
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  filterFunc: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  ItemComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  PlaceholderComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  searchInputPlaceholder: PropTypes.string,
  isBlock: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasPreselectedValue: PropTypes.bool,
  resetFilter: PropTypes.bool,
  hasArrow: PropTypes.bool,
};

Dropdown.defaultProps = {
  label: "",
  value: undefined,
  options: [],
  placeholder: "",
  id: undefined,
  filterFunc: undefined,
  ItemComponent: ({ value, isSelected, filter }) => (
    <ItemComponentWrapper isSelected={isSelected}>{value}</ItemComponentWrapper>
  ),
  PlaceholderComponent: ({ value }) => (
    <PlaceholderComponentWrapper>{value}</PlaceholderComponentWrapper>
  ),
  searchInputPlaceholder: "",
  isBlock: false,
  isDisabled: false,
  hasPreselectedValue: false,
  resetFilter: false,
  hasArrow: true,
};
