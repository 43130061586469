import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import spriteRed from "../../assets/royal-canin.sprite-brand1-xs.svg";

const Wrapper = styled.label`
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: text-top;

  input {
    position: absolute;
    opacity: 0;
    margin-right: -1rem;
    &:focus + input::before {
      box-shadow: 0 0 0 1pt #000;
      outline: none;
      transition: 0.1s;
    }
  }
  .label {
    padding-left: 2rem;
    line-height: 24px;
    color: #666;
    user-select: none;

    &::before {
      content: "";
      display: inline-block;
      border: 1px solid #d7d7d7;
      background-color: #fff;
      width: 22px;
      height: 22px;
      position: absolute;
      left: 0;
      border-radius: 3px;
    }
    ${({ isChecked }) =>
      isChecked &&
      css`
        &:after {
          content: "";
          display: inline-block;
          background-image: url(${spriteRed});
          background-position: 36.36% 100%;
          width: 22px;
          background-repeat: no-repeat;
          position: absolute;
          top: -2px;
          left: 1px;
          height: 30px;
          overflow: hidden;
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
        }
      `}
  }
`;

const Checkbox = ({ isChecked = false, setIsChecked, label, className }) => {
  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Wrapper isChecked={isChecked} className={className}>
      <input
        type="checkbox"
        checked={typeof isChecked === "boolean" ? isChecked : false}
        onChange={handleChange}
      />
      <span className={classNames("label", isChecked && "checked")}>
        {label}
      </span>
    </Wrapper>
  );
};

export default styled(Checkbox)``;

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  setIsChecked: PropTypes.func.isRequired,
  label: PropTypes.string,
};

Checkbox.defaultProps = {
  label: "",
  isChecked: undefined,
};
