import React from "react";
import { get } from "lodash";
import styled from "styled-components";
import Fieldset from "../../../Fieldset";
import RadioInput from "../../../RadioInput";
import colors from "../../../../styles/colors";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .boolean {
    width: 24px;
    height: 24px;
    border: 1px solid #d7d7d7;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${({ isSelected }) =>
        isSelected ? colors.brand1 : "transparent"};
    }
  }
  .label {
    height: 100%;
    padding-left: 8px;
    font-weight: 100;
    font-family: RC TYPE, Roboto, Avenir, Helvetica, Arial, sans-serif;
  }
`;

const StyledRadioInput = styled(RadioInput)`
  ul {
    height: 50px;
    li {
      width: auto;
      margin-right: 30px;
    }
  }
`;

export default ({ data, className, value, doAnswer }) => {
  const ItemComponent = ({ isSelected, value }) => (
    <Wrapper isSelected={isSelected}>
      <div className="boolean">
        <div className="dot" />
      </div>
      <div className="label">{get(data, "getLabelFromOption")(value)}</div>
    </Wrapper>
  );
  return (
    <div className={className}>
      <div className="question__inner">
        <Fieldset label={get(data, "label")}>
          <StyledRadioInput
            options={get(data, "options")}
            value={value}
            onChange={doAnswer}
            borderWidth="0px"
            ItemComponent={ItemComponent}
          />
        </Fieldset>
      </div>
    </div>
  );
};
