import React, { useEffect, useState } from "react";
import is from "is_js";
import { get } from "lodash";
import styled from "styled-components";
import Fieldset from "../../../Fieldset";
import NumberInput from "../../../NumberInput";
import Select from "../../../Dropdown";
import spriteRed from "../../../../assets/royal-canin.sprite--breed--brand1--xs.svg";

const ageUnitOptions = ["months", "years"];

const Wrapper = styled(Fieldset)`
  .age-container {
    display: flex;
    gap: 2px;
  }

  ${NumberInput} {
    flex: 0 0 102px;
    padding-right: 30px;
    input {
      border: none !important;
      border-bottom: 1px solid #d7d7d7 !important;
      border-radius: 0 !important;
      width: 30px !important;
    }
  }
  .ageUnit-input {
    flex-basis: 100%;
  }
  .age-error-icon {
    position: absolute;
    top: 3px;
    left: 0;
    transform: translateX(-25px);
    background: url(${spriteRed}) -124px -7px no-repeat;
    width: 17px;
    height: 17px;
  }
  .age-error-label {
    background: #e2001a;
    padding: 10px 25px;
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;

    @media (max-width: 768px) {
      position: relative;
      width: unset;
    }
  }
`;

const QuestionInnerAge = styled.div`
  position: relative;
`;

export default ({ data, className, value, doAnswer, hasError, errorText }) => {
  const [age, setAge] = useState(undefined);
  const [ageUnit, setAgeUnit] = useState("months");

  useEffect(() => {
    if (ageUnit !== undefined && age > 0) {
      doAnswer(ageUnit === "months" ? age : 12 * age);
    } else doAnswer(undefined);
  }, [age, ageUnit]);

  useEffect(() => {
    if (is.integer(value)) {
      if (value % 12 === 0) {
        setAgeUnit("years");
        setAge(value / 12);
      } else {
        setAgeUnit("months");
        setAge(value);
      }
    }
  }, [value]);

  const ItemComponent = (props) => (
    <div>
      {/* eslint-disable-next-line no-nested-ternary,react/destructuring-assignment */}
      {ageUnitOptions.includes(props.value)
        ? props.value === "months"
          ? get(data, "monthsLabel")
          : get(data, "yearsLabel")
        : ""}
    </div>
  );

  return (
    <div className={className}>
      <QuestionInnerAge className="question__inner">
        <Wrapper label={get(data, "label")}>
          <div className="age-container">
            <NumberInput value={age} onChange={setAge} />

            <div className="ageUnit-input">
              <Select
                value={ageUnit}
                options={ageUnitOptions}
                onChange={setAgeUnit}
                ItemComponent={ItemComponent}
                PlaceholderComponent={ItemComponent}
                isBlock
              />
            </div>
          </div>
          {hasError && (
            <>
              <i className="age-error-icon" />
              <div className="age-error-label">{errorText}</div>
            </>
          )}
        </Wrapper>
      </QuestionInnerAge>
    </div>
  );
};
