import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import logo from "../../assets/logo--crown.png";

const Wrapper = styled.div`
  background-color: #d7d7d7;
  opacity: 0.5;
  z-index: 1000;

  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .rotator {
    height: 90px;
    width: 90px;
    border: 4px solid ${colors.brand1};
    border-right-color: ${colors.grey};
    animation: rotate 0.8s linear infinite;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @keyframes rotate {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Spinner = ({ speed }) => (
  <Wrapper>
    <div className="rotator">{/*<img src={logo} alt="" />*/}</div>
  </Wrapper>
);

export default Spinner;
