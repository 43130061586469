import React, { useState, useEffect } from "react";
import is from "is_js";
import PropTypes from "prop-types";
import styled from "styled-components";
import Wrapper from "./styled";

const FormInput = ({
  label,
  value,
  onChange,
  suffix,
  isSearch,
  isBlock,
  isRequired,
  errorMessage,
  ...props
}) => {
  const [$value, setValue] = useState("");

  const changeHandler = (e) => {
    setValue(e.target.value);
    onChange(e.target.value, e);
  };

  const [_errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => setErrorMessage(errorMessage), [errorMessage]);

  return (
    <Wrapper
      hasValue={!!$value && $value.length !== 0}
      isSearch={isSearch}
      isBlock={isBlock}
      hasError={_errorMessage !== undefined}
      {...props}
    >
      {label && <div className='label'>{label}</div>}
      <div className='inner'>
        {isSearch && (
          <div className='search-icon'>
            <div className='inner' />
          </div>
        )}
        <input type='text' value={$value} onChange={changeHandler} />
        {suffix && <div className='suffix'>{suffix}</div>}
      </div>
      {_errorMessage && (
        <div className='error-message'>
          {_errorMessage} {typeof _errorMessage}
        </div>
      )}
    </Wrapper>
  );
};

export default styled(FormInput)``;

FormInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  suffix: PropTypes.string,
  isSearch: PropTypes.bool,
  isBlock: PropTypes.bool,
  isRequired: PropTypes.bool,
  errorMessage: PropTypes.string,
};

FormInput.defaultProps = {
  label: "",
  value: "",
  onChange: () => {},
  suffix: "",
  isSearch: false,
  isBlock: false,
  errorMessage: undefined,
  isRequired: false,
};
