import is from "is_js";

const convertNumberFormat = (value) => {
    const result =  String(value).replace(/,/g, '.');

    if (is.number(Number(result)) && Number(result) !== 0) {
        return Number(result);
    } else {
        return undefined;
    }        
}

export default convertNumberFormat;
