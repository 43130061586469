import React, { useEffect, useState } from "react";
import { get } from "lodash";
import is from "is_js";
import { useWindowSize } from "rooks";
import Wrapper from "./styled";
import BreedQuestion from "./questions/breed";
import GenderQuestion from "./questions/gender";
import AgeQuestion from "./questions/age";
import WeightQuestion from "./questions/weight";
import NeuteredQuestion from "./questions/neutered";
import ActivityQuestion from "./questions/activity";
import BCSQuestion from "./questions/bcs";
import Button from "../Button";
import BreakPoints from "../utils/break-points";
import FlipCard from "../FlipCard";
import Spinner from "../Spinner";

const DailyPortionTool = ({
  isDemo = false,
  dataApiUrl,
  resultApiUrl,
  specieCode,
  productId,
  calcText,
  periodText,
  restartText,
  resText,
  continueText,
  editText,
  bcsText,
  errorMessage = "something went wrong ...",
  breedValue = undefined,
  pfState = undefined,
}) => {
  const { innerWidth: windowWidth } = useWindowSize();

  const [breedOptions, setBreedOptions] = useState([]);
  const [isBreedDefined, setIsBreedDefined] = useState(false);

  const [questionsState, setQuestionsState] = useState({
    breed: {},
    gender: {},
    age: {},
    weight: {},
    neutered: {},
    activity: {},
    bcs: {},
  });

  const [state, setState] = useState({
    isFormValid: false,
    isBCS: false,
    isShowingResults: false,
    result: "",
  });

  const [petState, setPetState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAgeError, setAgeError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLifestageReady, setLifestageReady] = useState(false);
  const [isPF, setIsPF] = useState(false);

  useEffect(() => {
    if (pfState !== undefined) {
      setIsPF(true);
      getAndShowResult(pfState);
    }
  }, [pfState]);

  useEffect(() => {
    if (petState.age && petState.breed && petState.gender) {
      checkLifestage();
    }
  }, [petState.age, petState.breed, petState.gender]);

  const checkLifestage = () => {
    setIsLoading(true);
    const dataApiLifeStage = `${`${window.location.origin}/${
      window.location.pathname.split("/").indexOf("en_hk") === -1?window.location.pathname.split("/")[1]:"hk/en_hk"
    }`}/api/Products/GetProductLifestage?genderCode=${petState.gender}&age=${
      petState.age
    }&breedCode=${petState.breed}&productId=${productId}`;

    fetch(dataApiLifeStage)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.validLifestage) {
            setLifestageReady(true);
            setAgeError(false);
          } else {
            setLifestageReady(false);
            setErrorText(result.errorMessage);
            setAgeError(true);
          }
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      );
  };

  const customAnalyticsEvent = (value) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "rationingToolInteraction",
      rationingToolInteraction: `${value}`,
    });
  };

  const answer = {
    breed: (value) => {
      setPetState((oldState) => ({
        ...oldState,
        breed: value,
      }));
      if (value !== undefined) customAnalyticsEvent("Breed");
    },
    size: (value) => {
      setPetState((oldState) => ({
        ...oldState,
        size: value,
      }));
      if (value !== undefined) customAnalyticsEvent("Pet size");
    },
    gender: (value) => {
      setPetState((oldState) => ({
        ...oldState,
        gender: value,
      }));
      if (value !== undefined) customAnalyticsEvent("Gender");
    },
    age: (value) => {
      setPetState((oldState) => ({
        ...oldState,
        age: value,
      }));
      if (value !== undefined) customAnalyticsEvent("Pet age");
    },
    weight: (value) => {
      setPetState((oldState) => ({
        ...oldState,
        weight: value,
      }));
      if (value !== undefined) customAnalyticsEvent("Pet weight");
    },
    isNeutered: (value) => {
      setPetState((oldState) => ({
        ...oldState,
        isNeutered: value,
      }));
      if (value !== undefined) customAnalyticsEvent("Pet neutered");
    },
    activity: (value) => {
      setPetState((oldState) => ({
        ...oldState,
        activity: value,
      }));
      if (value !== undefined) customAnalyticsEvent("Pet activity");
    },
    bcs: (value) => {
      setPetState((oldState) => ({
        ...oldState,
        bcs: value,
      }));
      if (value !== undefined) customAnalyticsEvent("Pet activity");
    },
  };

  const etl = async () => {
    let url;
    if (isDemo) {
      url = "http://localhost:6006/data/product-finder/dog.json";
    } else {
      url = dataApiUrl;
    }
    setIsLoading(true);
    try {
      const data = await (await fetch(url)).json();

      const [step1, step3, step4, step5, step6, step8, step9] = [
        get(data, "ResponseData.groupedResponse.Step1"),
        get(data, "ResponseData.groupedResponse.Step3"),
        get(data, "ResponseData.groupedResponse.Step4"),
        get(data, "ResponseData.groupedResponse.Step5"),
        get(data, "ResponseData.groupedResponse.Step7"),
        get(data, "ResponseData.groupedResponse.Step6"),
        get(data, "ResponseData.groupedResponse.Step8"),
      ];

      const bcs = {
        title: get(step9, "bcs.metadata.translatedLabel"),
        options: Object.keys(get(step9, "bcs.possibleValues")),
        getLabelFromValue: (value) =>
          get(step9, `bcs.possibleValues.${value}.translatedLabel`),
        getDescriptionFromValue: (value) => {
          const mappedSuffixes = new Map([
            ["3", "low"],
            ["5", "normal"],
            ["7", "high"],
          ]);
          return get(
            step9,
            `bcs.possibleValues.${value}.translatedbcs_${mappedSuffixes.get(
              value
            )}_description`
          );
        },
        getImageAltFromValue: (value) => {
          const map = new Map([
            ["3", "underWeight"],
            ["5", "idealWeight"],
            ["7", "overweight"],
          ]);
          return get(step9, `bcs.${map.get(value)}ImageAlt`);
        },
        getImagePathFromValue: (value) => {
          const map = new Map([
            ["3", "underWeight"],
            ["5", "idealWeight"],
            ["7", "overweight"],
          ]);
          return get(step9, `bcs.${map.get(value)}ImagePath`);
        },
      };

      const size = {
        label: get(step4, "tip"),
        placeholder: get(step4, "tip"),
        unknownLabel: get(step4, "tip"),
        options: Object.keys(get(step4, "size.possibleValues")),
        getLabelFromOption: (value) => {
          if (value !== undefined) {
            return get(step4, `size.possibleValues.${value}.translatedLabel`);
          }
          return get(step4, "tip");
        },
      };

      const breed = {
        label: get(step3, "breedSearchLabel"),
        placeholder: get(step3, "breedSearchPlaceHolder"),
        unknownLabel: get(step3, "unknownBreedPlaceHolder"),
        options: Object.keys(get(step3, "breedCode.possibleValues")),
        getLabelFromOption: (value) => {
          if (value !== undefined) {
            return get(step3, `breedCode.possibleValues.${value}.label`);
          }
          return get(step3, "breedSearchPlaceHolder");
        },
      };

      setBreedOptions(Object.keys(get(step3, "breedCode.possibleValues")));

      const gender = {
        label: get(step1, "genderCode.metadata.translatedLabel"),
        options: Object.keys(get(step1, "genderCode.possibleValues")),
        getLabelFromOption: (value) =>
          get(step1, `genderCode.possibleValues.${value}.translatedLabel`),
      };

      const age = {
        label: get(step1, "age.metadata.translatedLabel"),
        monthsLabel: get(step1, "months"),
        yearsLabel: get(step1, "Years"),
      };

      const weight = {
        label: get(step6, "weight.petWeight"),
        suffix: get(step6, "weight.petWeightkg"),
      };

      const isNeutered = {
        label: get(step5, "neutered.metadata.translatedLabel"),
        options: Object.keys(get(step5, "neutered.possibleValues")).reverse(),
        getLabelFromOption: (option) =>
          get(step5, `neutered.possibleValues.${option}.translatedLabel`),
      };

      const activity = {
        label: get(step8, "petActivityCode.metadata.translatedLabel"),
        options: Object.keys(get(step8, "petActivityCode.possibleValues")),
        getLabelFromOption: (option) => {
          const value = `${get(
            step8,
            `petActivityCode.possibleValues.${option}.translatedLabel`
          )}`;

          if (is.string(value)) {
            if (value.includes("<")) return value.split("<")[0];
            if (value.includes(">")) return value.split(">")[0];
          }

          return value;
        },
        getDescriptionFromOption: (option) => {
          const value = `${get(
            step8,
            `petActivityCode.possibleValues.${option}.translatedLabel`
          )}`;
          if (is.string(value)) {
            if (value.includes(">") && value.split(">").length === 2) {
              return `>${value.split(">")[1]}`;
            }
            if (value.includes("<") && value.split("<").length === 2) {
              return `<${value.split("<")[1]}`;
            }
          }

          return "";
        },
      };

      setQuestionsState((oldState) => ({
        ...oldState,
        breed,
        gender,
        age,
        weight,
        isNeutered,
        activity,
        size,
        bcs,
      }));
    } catch (error) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (breedOptions.length !== 0) {
      setIsBreedDefined(breedOptions.includes(breedValue));
      if (breedOptions.includes(breedValue)) {
        setPetState((oldState) => ({
          ...oldState,
          breed: breedValue,
        }));
      } else {
        setPetState((oldState) => ({
          ...oldState,
          breed: undefined,
        }));
      }
    }
  }, [breedOptions, breedValue]);

  const getAndShowResult = async (pfState = {}) => {
    let result = "20g";
    if (!isDemo && state.isFormValid && Object.keys(pfState).length === 0) {
      setIsLoading(true);
      let url = `${resultApiUrl}?type=1&genderCode=${petState.gender}&age=${
        petState.age
      }&speciesCode=${specieCode.toLowerCase()}&breedCode=${
        petState.breed
      }&neutered=${petState.isNeutered}&weight=${petState.weight}&bcs=${
        petState.bcs
      }&petActivityCode=${petState.activity}&productId=${productId}`;

      if (petState.size) url = `${url}&size=${petState.size}`;

      try {
        const response = await (await fetch(url)).json();
        if (response.ResponseData.DryRation !== null) {
          result = `${response.ResponseData.DryRation[0].quantityPerDay} ${response.ResponseData.DryRation[0].unit}`;
        } else {
          result = `${response.ResponseData.WetRation[0].quantityPerDay} ${response.ResponseData.WetRation[0].unit}`;
        }
        customAnalyticsEvent("Display result");
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    } else if (!isDemo) {
      setIsLoading(true);
      let url = `${resultApiUrl}?type=1&genderCode=${pfState.gender}&age=${
        pfState.age
      }&speciesCode=${specieCode.toLowerCase()}&breedCode=${
        pfState.breed
      }&neutered=${pfState.isNeutered}&weight=${pfState.weight}&bcs=${
        pfState.bcs
      }&petActivityCode=${pfState.activity}&productId=${productId}`;
      if (pfState.size) url = `${url}&size=${pfState.size}`;
      try {
        const response = await (await fetch(url)).json();
        result = `${response.ResponseData.DryRation[0].quantityPerDay} ${response.ResponseData.DryRation[0].unit}`;
        customAnalyticsEvent("Display result");
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    }

    setState((oldState) => ({
      ...oldState,
      result,
      isShowingResults: true,
    }));
  };

  const restart = () => {
    setPetState({});
    customAnalyticsEvent("Start a new calculation");
    setIsPF(false);
    setIsSecondary(false);
    setIsBCSAnswered(false);
    setState((oldState) => ({
      ...oldState,
      isBCS: false,
      isFormValid: false,
      isShowingResults: false,
    }));
  };

  useEffect(() => {
    etl();
  }, []);

  const [isSecondary, setIsSecondary] = useState(false);
  const [isBCSAnswered, setIsBCSAnswered] = useState(false);

  useEffect(() => {
    if (!isBCSAnswered) {
      setState((oldState) => ({
        ...oldState,
        isBCS: isSecondary,
        isFormValid: false,
      }));
    } else {
      setState((oldState) => ({
        ...oldState,
        isBCS: isSecondary,
        isFormValid: !!isLifestageReady,
      }));
    }
  }, [isSecondary, isBCSAnswered, isLifestageReady]);

  const successCard = () => (
    <>
      <span className="result-container">
        <span className="result-icon" />
        <span className="result-portion">{state.result}</span>
        <span className="result-separator"> /</span>
        <span className="result-text">{periodText || "day"}</span>
      </span>
      {bcsText && petState?.bcs !== "5" && (
        <span className="bcs-message">
          <i className="info-icon" />
          {bcsText}
        </span>
      )}
    </>
  );

  useEffect(() => {
    if (!isSecondary) {
      if (
        petState.breed !== undefined &&
        petState.gender !== undefined &&
        petState.age !== undefined &&
        petState.weight !== undefined &&
        petState.isNeutered !== undefined &&
        petState.activity !== undefined
      ) {
        setState((oldState) => ({
          ...oldState,
          isFormValid: !!isLifestageReady,
        }));
      } else {
        setState((oldState) => ({
          ...oldState,
          isFormValid: false,
        }));
      }
      if (
        specieCode.toLowerCase() === "dog" &&
        petState.breed === "mixed_breed" &&
        petState.size === undefined
      ) {
        setState((oldState) => ({
          ...oldState,
          isFormValid: false,
        }));
      }
    } else if (petState.bcs !== undefined) {
      setState((oldState) => ({
        ...oldState,
        isFormValid: !!isLifestageReady,
      }));
    }
  }, [petState, isSecondary, isLifestageReady]);

  return (
    <Wrapper isMobile={windowWidth < BreakPoints.M}>
      {isLoading && <Spinner />}
      {!state.isShowingResults && !isPF && (
        <div className="questions">
          {!state.isBCS ? (
            <>
              <BreedQuestion
                className="question"
                data={questionsState.breed}
                doAnswerBreed={answer.breed}
                doAnswerSize={answer.size}
                value={isBreedDefined ? breedValue : petState.breed}
                preselectedValue={isBreedDefined ? breedValue : undefined}
                isDog={specieCode && specieCode.toLowerCase() === "dog"}
                dogSize={questionsState.size}
                sizeValue={petState.size}
                breedSearchLabel={questionsState.breed.label}
              />
              <GenderQuestion
                className="question"
                data={questionsState.gender}
                doAnswer={answer.gender}
                value={petState.gender}
              />
              <AgeQuestion
                className="question"
                data={questionsState.age}
                doAnswer={answer.age}
                value={petState.age}
                hasError={isAgeError}
                errorText={errorText}
              />
              <WeightQuestion
                className="question"
                data={questionsState.weight}
                doAnswer={answer.weight}
                value={petState.weight}
              />
              <NeuteredQuestion
                className="question"
                data={questionsState.isNeutered}
                doAnswer={answer.isNeutered}
                value={petState.isNeutered}
              />
              <ActivityQuestion
                className="question"
                data={questionsState.activity}
                doAnswer={answer.activity}
                value={petState.activity}
              />
            </>
          ) : (
            <BCSQuestion
              data={questionsState.bcs}
              type={specieCode.toLowerCase()}
              doAnswer={answer.bcs}
              value={petState.bcs}
            />
          )}
          {!state.isBCS ? (
            <div className="btn-wrapper">
              <Button
                variant="secondary"
                label={continueText || "Continue"}
                onClick={() => {
                  setIsSecondary(true);
                  customAnalyticsEvent("Continue");
                }}
                disabled={!state.isFormValid}
              />
            </div>
          ) : (
            <div className="btn-wrapper">
              <Button
                label={calcText || "Calculate portion"}
                onClick={() => {
                  getAndShowResult();
                  customAnalyticsEvent("Calculate portion");
                }}
                disabled={!state.isFormValid}
              />
              <Button
                variant="secondary"
                label={editText || "Edit pets information"}
                onClick={() => {
                  setIsSecondary(false);
                  customAnalyticsEvent("Edit pets information");
                }}
              />
            </div>
          )}
        </div>
      )}
      {state.isShowingResults && (
        <>
          {isError ? (
            <div className="error">{errorMessage}</div>
          ) : (
            <FlipCard
              title={resText || "Recommended daily portion for your pet"}
              content={successCard()}
            />
          )}
          <div className="btn-result btn-wrapper">
            <Button
              variant="secondary"
              label={restartText || "Restart"}
              onClick={() => restart()}
            />
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default DailyPortionTool;
