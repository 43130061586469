import React, { useEffect, useState } from "react";
import { get } from "lodash";
import styled from "styled-components";
import Fieldset from "../../../Fieldset";
import FormInput from "../../../FormInput";
import convertNumberFormat from "../../../utils/convertNumberFormat/index";

const StyledFormInput = styled(FormInput)`
  width: 103px;
`;

export default ({ data, className, doAnswer, value }) => {
  const [weight, setWeight] = useState(value ? `${value}` : "0");

  useEffect(() => {
    doAnswer(convertNumberFormat(weight));
  }, [weight]);

  return (
    <div className={className}>
      <div className="question__inner">
        <Fieldset label={get(data, "label")}>
          <StyledFormInput
            value={weight}
            suffix={get(data, "suffix")}
            onChange={setWeight}
            errorMessage={undefined}
          />
        </Fieldset>
      </div>
    </div>
  );
};
