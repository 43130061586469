const colors = {
  red   : "#e2001a",
  green : "#008900",
  grey  : "#d7d7d7",
  font  : "#666",

  brand1 : "#E2001A",
  brand2 : "#808285",
  brand3 : "#FFFFFF",
  brand4 : "#F6F6F6",

  text             : "#666666",
  iconography      : "#767676",
  hyperlink        : "#444444",
  interface        : "#D7D7D7",
  "interface-dark" : "#333333",

  function : "#5CA9AD",
  success  : "#008900",
  warning  : "#EE8B00",
  error    : "#C03344",
  inactive : "#EAEAEA",
};

export default colors;
