import React from "react";
import { get } from "lodash";

import styled, { css } from "styled-components";
import Fieldset from "../../../Fieldset";
import RadioInput from "../../../RadioInput";
import spriteRed from "../../../../assets/royal-canin.sprite-brand1-xs.svg";
import sprite from "../../../../assets/royal-canin.sprite--iconography--xs.svg";

const activeMixin = css`
  .icon {
    background: ${({ male }) =>
      male
        ? `url(${spriteRed}) -171px -168px no-repeat`
        : `url(${spriteRed}) -148px -40px no-repeat`};
  }
  .text {
    color: #e2001a;
  }
`;
const ItemComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 46px;
  justify-content: center;
  align-items: center;
  .icon {
    background: url(${sprite}) -173px -169px no-repeat;
    background: ${({ male }) =>
      male
        ? `url(${sprite}) -171px -168px no-repeat`
        : `url(${sprite}) -148px -40px no-repeat`};
    display: inline-block;
    flex: 0 0 17px;
    height: 17px;
  }
  .text {
    margin-left: 6px;
    font-size: 16px;
    font-family: RC TYPE, Roboto, Avenir, Helvetica, Arial, sans-serif;
    font-weight: 500;
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      ${activeMixin}
    `}

  &:hover {
    ${activeMixin}
  }
`;

export default ({ data, className, value, doAnswer }) => {
  const ItemComponent = ({ isSelected, value }) => (
    <ItemComponentWrapper male={value === "male"} isSelected={isSelected}>
      <div className="icon" />
      <div className="text">{get(data, "getLabelFromOption")(value)}</div>
    </ItemComponentWrapper>
  );

  return (
    <div className={className}>
      <div className="question__inner">
        <Fieldset label={get(data, "label")}>
          <RadioInput
            value={value}
            options={get(data, "options")}
            onChange={doAnswer}
            ItemComponent={ItemComponent}
          />
        </Fieldset>
      </div>
    </div>
  );
};
