import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import is from "is_js";
import Fieldset from "../../../Fieldset";
import Dropdown from "../../../Dropdown";
import Checkbox from "../../../Checkbox";
import formatOptionString from "../../../utils/formatOptionString";

export default ({
  data,
  className,
  doAnswerBreed,
  value,
  sizeValue,
  preselectedValue = undefined,
  isDog = false,
  dogSize,
  doAnswerSize,
  breedSearchLabel,
}) => {
  const [isBreedUnknown, setIsBreedUnknown] = useState(value === "mixed_breed");
  const [breed, setBreed] = useState(value);
  const [size, setSize] = useState(sizeValue);
  const [options, setOptions] = useState([]);
  const [isSize, setIsSize] = useState(false);

  useEffect(() => {
    if (isBreedUnknown) {
      setIsSize(isDog);
      setBreed("mixed_breed");
      doAnswerBreed(breed);
      doAnswerSize(size);
    } else {
      setIsSize(false);
      setBreed(breed === "mixed_breed" ? undefined : breed);
      doAnswerBreed(breed);
      setSize(undefined);
      doAnswerSize(undefined);
    }

    if (preselectedValue) {
      doAnswerBreed(preselectedValue);
    } else {
      doAnswerBreed(breed);
      doAnswerSize(size);
    }
  }, [isBreedUnknown, breed, size, isSize]);

  const filterFunc = (filter, option) => {
    try {
      return get(
        isSize ? dogSize : data,
        "getLabelFromOption"
      )(option)
        .toLowerCase()
        .includes(filter.toLowerCase());
    } catch (e) {
      return true;
    }
  };

  const PlaceholderComponent = (props) => {
    const placeholderValue = useMemo(() => {
      if (props.value === "mixed_breed" && !isDog) {
        return get(data, "placeholder");
      }

      return props.value !== undefined
        ? get(isSize ? dogSize : data, "getLabelFromOption")(props.value)
        : get(isSize ? dogSize : data, "placeholder");
    }, [props, data, dogSize, isSize]);

    return <div>{placeholderValue}</div>;
  };

  const TypeItemComponent = ({ value, filter }) => {
    let html =
      value !== undefined
        ? get(data, "getLabelFromOption")(value)
        : get(isSize ? dogSize : data, "placeholder");

    if (is.string(filter) && filter.length !== 0) {
      html = formatOptionString(html, filter);
    } else {
      html = get(isSize ? dogSize : data, "getLabelFromOption")(value);
    }

    return (
      // eslint-disable-next-line react/no-danger
      <div dangerouslySetInnerHTML={{ __html: html }} />
    );
  };

  useEffect(() => {
    const compare = (option1, option2) => {
      if (
        get(data, "getLabelFromOption")(option1) <
        get(data, "getLabelFromOption")(option2)
      ) {
        return -1;
      }
      if (
        get(data, "getLabelFromOption")(option1) >
        get(data, "getLabelFromOption")(option2)
      ) {
        return 1;
      }
      return 0;
    };
    const unsortedOptions = get(data, "options", []);
    const shallowOptions = [...unsortedOptions];
    setOptions(shallowOptions.sort(compare));
  }, [data, isSize]);

  return (
    <div className={className}>
      <div className="question__inner">
        <Fieldset label={breedSearchLabel}>
          <Dropdown
            options={isSize ? get(dogSize, "options", []) : options}
            {...(!isBreedUnknown ? { filterFunc } : {})}
            isBlock
            onChange={isSize ? setSize : setBreed}
            value={isSize ? sizeValue : value}
            ItemComponent={TypeItemComponent}
            PlaceholderComponent={PlaceholderComponent}
            isDisabled={(isBreedUnknown && !isDog) || !!preselectedValue}
            hasPreselectedValue={!!preselectedValue}
            resetFilter={isBreedUnknown}
            hasArrow={isBreedUnknown && isDog}
          />
          {!preselectedValue && (
            <div className="checkbox-wrapper">
              <Checkbox
                label={get(data, "unknownLabel")}
                isChecked={isBreedUnknown}
                setIsChecked={setIsBreedUnknown}
              />
            </div>
          )}
        </Fieldset>
      </div>
    </div>
  );
};
