import styled from "styled-components";
import spriteIcons from "../../assets/royal-canin.sprite--iconography.svg";
import spriteIconsXs from "../../assets/royal-canin.sprite--iconography--xs.svg";

const Wrapper = styled.div`
  position: relative;
  .questions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .question {
      flex: ${({ isMobile }) =>
        isMobile ? "0 0 100%" : "0 0 calc((100% / 3) - (2rem / 3))"};

      margin-bottom: 16px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .question__inner {
        padding-right: ${({ isMobile }) => (isMobile ? "0" : "40px")};
        padding-bottom: ${({ isMobile }) => (isMobile ? "40px" : "10px")};
      }
    }
  }

  .btn-wrapper {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      width: 232px;
      margin-bottom: 16px;
      background: #e2001a;
      color: #fff;

      &:disabled {
        background: #d7d7d7;
      }
    }
  }

  .result-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .result-portion {
    font-size: ${({ isMobile }) => (isMobile ? "22px" : "26px")};
    font-weight: 400;
    color: #e2001a;
    margin-right: 10px;
  }

  .result-icon {
    display: inline-block;
    margin-right: 10px;
    ${({ isMobile }) => isMobile && "flex: 0 0 100%"};
  }

  .bcs-message {
    display: block;
    margin-top: 20px;
  }

  .info-icon {
    display: inline-block;
    margin-right: 10px;
    height: 16px;
    position: relative;
    top: 2px;
  }

  .info-icon::after {
    background-image: url(${spriteIconsXs});
    background-position: 63% 30%;
    content: "";
    display: inline-block;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    overflow: hidden;
    transform-origin: 50% 50%;
    position: relative;
  }

  .result-icon::after {
    background-image: url(${spriteIcons});
    background-position: 60% 20%;
    content: "";
    display: inline-block;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    overflow: hidden;
    transform-origin: 50% 50%;
    position: relative;
  }
  .btn-result {
    margin-top: 20px;
  }
  legend {
    padding-bottom: 15px;
  }
  .checkbox-wrapper {
    margin-top: 5px;
  }
`;
export default Wrapper;
