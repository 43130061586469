import styled, { css } from "styled-components";

import spriteRed from "../../assets/royal-canin.sprite-brand1-xs.svg";
import spriteGrey from "../../assets/royal-canin.sprite--iconography--xs.svg";
import colors from "../../styles/colors";

const Wrapper = styled.div`
  max-width: ${({ isBlock }) => (isBlock ? "100%" : "320px")};
  position: relative;
  border-bottom: 1px solid #808285;
  font-family: sans-serif;
  min-width: 100px;

  .label {
    font-family: RC TYPE, Roboto, Avenir, Helvetica, Arial, sans-serif;
    padding: 0;
    font-weight: 500;
    z-index: 110;

    text-indent: 1px;
    white-space: nowrap;
    transition: all 0.2s ease;
    font-size: 16px;
    line-height: 24px;
    color: #666;
    padding-left: ${({ hasValue, isSearch, isInputOpen }) => {
      const shouldGoDown = !isSearch ? !hasValue : !isInputOpen && !hasValue;
      return shouldGoDown && isSearch ? "28px" : "0px";
    }};

    ${({ hasValue, isSearch, isInputOpen }) => {
      const shouldGoDown = !isSearch ? !hasValue : !isInputOpen && !hasValue;
      return (
        shouldGoDown &&
        css`
          transform: translateY(2.3rem);
        `
      );
    }}
    pointer-events: none;
  }

  .main {
    display: flex;
    .search-icon {
      position: absolute;
      width: 24px;
      height: 48px;
      display: flex;
      align-items: center;
      cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

      .inner {
        width: 24px;
        height: 24px;
        background: url(${spriteGrey}) 81% 83.71%;
        background-size: 345px;
      }
    }
    .handler {
      flex-basis: 100%;
      display: flex;
      cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
      background-color: ${({ isDisabled, hasPreselectedValue }) =>
        isDisabled && !hasPreselectedValue ? "#D7D7D7" : "transparent"};

      .search-icon {
        position: absolute;
        width: 24px;
        height: 48px;
        display: ${({ isDisabled, hasPreselectedValue }) =>
          isDisabled && hasPreselectedValue ? "none" : "flex"};
        align-items: center;

        .inner {
          width: 24px;
          height: 24px;
          background: url(${spriteGrey}) 81% 83.71%;
          background-size: 345px;
        }
      }
      button {
        min-height: 48px;
        padding: 0.9em 3rem 0.9em 0;
        border-radius: 2.5px;
        border: none;
        display: block;
        width: 100%;
        position: relative;
        z-index: 100;
        appearance: none;
        text-align: left;
        font-size: 16px;
        color: #666;
        background: transparent;
        font-weight: 100;
        font-family: RC TYPE, Roboto, Avenir, Helvetica, Arial, sans-serif;
        padding-left: ${({ isSearch, hasPreselectedValue }) =>
          isSearch && !hasPreselectedValue ? "28px" : "0px"};
        cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

        &::after {
          display: ${({ isDisabled, hasPreselectedValue, hasArrow }) =>
            !hasArrow || (isDisabled && hasPreselectedValue)
              ? "none"
              : "block"};
          background-image: url(${spriteGrey});
          width: 24px;
          height: 32px;
          background-position: 0 42.86%;
          z-index: 100;
          content: "";
          position: absolute;
          right: 11.5px;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
        }
      }
      input {
        margin-bottom: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: transparent;
        border: ${colors.font};
        color: ${colors.font};
        line-height: 46px;
        font-size: 16px;
        font-weight: 300;
        padding-left: 28px;
        outline: none;
        flex-basis: 100%;
      }

      &:focus {
        box-shadow: 0 0 0 1pt #000;
      }

      &::-ms-expand {
        display: none;
      }
    }
  }

  .list {
    background-color: #fff;
    margin-top: 0;
    list-style-type: none;
    padding-left: 0;
    text-align: left;
    border-radius: 3px;
    border: 1px solid #ddd;
    display: none;
    position: absolute;
    overflow: auto;
    max-height: 300px;
    left: 0;
    right: 0;
    cursor: pointer;
    z-index: 110;

    &.open {
      display: block;

      &:hover .dropdown-list-item.selected {
        background: #fff;
      }
    }

    .search-box {
      display: flex;
      .search-icon {
        position: absolute;
        width: 24px;
        height: 48px;
        display: flex;
        align-items: center;

        .inner {
          width: 24px;
          height: 24px;
          background: url(${spriteGrey}) 81% 83.71%;
          background-size: 345px;
        }
      }
      input {
        margin-bottom: 0;
        box-shadow: none;
        background-color: transparent;
        border: ${colors.font};
        color: ${colors.font};
        line-height: 46px;
        font-size: 16px;
        font-weight: 300;
        padding-left: 28px;
        outline: none;
      }
      border-bottom: 1px solid #d7d7d7;
    }

    .item {
      position: relative;
      padding: 10px;
      font-size: 14px;
      line-height: 1.5;
      color: #666;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      & > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        user-select: none;

        &.selected::after {
          display: none;
        }

        &:hover {
          background: #fff;
        }
      }

      &:hover {
        background: #f2f2f2 !important;
      }

      &.selected {
        background: #f2f2f2;
      }

      &.selected::after {
        content: "";
        display: inline-block;
        background-image: url(${spriteRed});
        background-position: 36.36% 100%;
        background-repeat: no-repeat;
        width: 24px;
        height: 32px;
        overflow: hidden;
        position: absolute;
        right: 10px;
        top: 3px;
        transform-origin: 50% 50%;
      }
    }
  }

  &:hover {
    button {
      &::after {
        background-image: url(${spriteRed});
      }
    }
  }

  &.open {
    button {
      ::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
`;

const ItemComponentWrapper = styled.span``;
const PlaceholderComponentWrapper = styled.b``;

export { Wrapper, ItemComponentWrapper, PlaceholderComponentWrapper };
