import bindReactComponent from "../bind-react-component";
import DailyPortionTool from "../components/DayliPortionTool";
import CheckPfURLParams from "../components/utils/checkPfUrlParams";

const targetEl = document.querySelector("#daily-portion");

if (targetEl) {
    const containerEl = document.querySelector(".daily-portion-tool-container");
    const specieCode = targetEl.getAttribute("data-dailyportion-speciecode");
    const productId = targetEl.getAttribute("data-dailyportion-productid");
    const breedValue = targetEl.getAttribute("data-dailyportion-breed-value");

    const triggerEl = document.querySelector(".daily-portion-button");

    // translations

    const calcText = targetEl.getAttribute(
        "data-dailyportion-translation-calculate"
    );
    const periodText = targetEl.getAttribute(
        "data-dailyportion-translation-day"
    );
    const restartText = targetEl.getAttribute(
        "data-dailyportion-translation-restart"
    );
    const resText = targetEl.getAttribute(
        "data-dailyportion-translation-result-text"
    );
    const bcsText = targetEl.getAttribute(
        "data-dailyportion-translation-bcsmessage"
    );

    const continueText = targetEl.getAttribute(
        "data-dailyportion-translation-continue"
    );
    const editText = targetEl.getAttribute(
        "data-dailyportion-translation-edit"
    );

    const errorMessage = targetEl.getAttribute(
        "data-dailyportion-translation-error"
    );

    const props = {
        dataApiUrl: `${`${window.location.origin}/${
            window.location.pathname.split("/").indexOf("en_hk") === -1?window.location.pathname.split("/")[1]:"hk/en_hk"
        }`}/api/Products/GetDailyPortionInputs?type=1&speciesCode=${specieCode.toLowerCase()}`,
        resultApiUrl: `${`${window.location.origin}/${
            window.location.pathname.split("/").indexOf("en_hk") === -1?window.location.pathname.split("/")[1]:"hk/en_hk"
        }`}/api/Products/GetProductRationing`,
        specieCode,
        productId,
        calcText,
        periodText,
        restartText,
        resText,
        errorMessage,
        breedValue,
        continueText,
        editText,
        bcsText,
    };

    if (triggerEl) {
        triggerEl.addEventListener("click", (e) => {
            e.preventDefault();
            bindReactComponent(DailyPortionTool, targetEl, props, true);
            containerEl.style.display = "block";
            triggerEl.style.display = "none";
        });
        const pfState = CheckPfURLParams();
        if (pfState !== undefined) {
            bindReactComponent(
                DailyPortionTool,
                targetEl,
                { ...props, pfState },
                true
            );
            containerEl.style.display = "block";
            triggerEl.style.display = "none";
        }
    }
}
