import React, { useEffect, useState, } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import AllyText from "../utils/AllyText";
import sprite from "../../assets/royal-canin.sprite-brand1-xs.svg";

const Wrapper = styled.label`
  display: inline-flex;
  max-width: 320px;
  align-items: center;
  position: relative;

  .btn-increment {
    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    display: inline-block;
    position: relative;
    vertical-align: middle;
    line-height: inherit;
    text-align: center;
    overflow: hidden;
    border: 2px solid transparent;
    border-radius: 999px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 300;
    width: auto;
    height: 36px;
    padding: 0;
    background-color: transparent;

    &.icon-minus::after,
    &.icon-plus::after {
      background-image: url(${sprite});
      width: 24px;
      content: "";
      display: inline-block;
      background-repeat: no-repeat;
      height: 32px;
      overflow: hidden;
      transform-origin: 50% 50%;
      position: relative;
    }
    &.icon-minus::after {
      background-position: 72.73% 14.29%;
    }
    &.icon-plus::after {
      background-position: 0 85.71%;
    }
  }
  .input__number-picker {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    color: #666;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    appearance: none;
    display: block;
    line-height: 1.5rem;
    font-family: sans-serif;
    width: 57px;
    margin-bottom: 0;
    padding: 11px 0;
    box-shadow: none;
    background-color: transparent;

    &.warning {
      color: #ee8b00;
      border-color: #ee8b00;
    }
    /* Remove default number controls: Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Remove default number controls: Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .validation-message {
    color: #ee8b00;
    position: absolute;
    font-size: 0.7rem;
    margin-top: 0.5rem;
    top: 100%;
    left: 0;
    min-width: 22em;
    text-align: left;
    font-family: sans-serif;
  }`;

const NumberInput = ({
  min,
  max,
  step,
  stepUpHint,
  stepDownHint,
  value,
  onChange,
  errorMessage,
  className,
},) => {
  const [
    valid,
    setIsValid,
  ] = useState(true,);

  useEffect(() => {
    setIsValid(!(value > max || value < min),);
  }, [
    value,
    min,
    max,
  ],);

  const handleChange = ($value,) => {
    onChange($value,);
  };

  const doStep = {
    up   : () => onChange(max >= value + step ? value + step : max,),
    down : () => onChange(min <= value - step ? value - step : min,),
  };

  return (
    <Wrapper className={classNames("input__number-picker-wrapper", className,)}>
      <button
        className="btn-increment icon-minus"
        aria-label={stepDownHint}
        type="button"
        onClick={doStep.down}
        disabled={value <= min}
      >
        <AllyText>{stepDownHint}</AllyText>
      </button>

      <input
        className={classNames("input__number-picker", !valid && "warning",)}
        name="number-picker"
        value={value}
        onChange={(e,) => handleChange(e.target.value,)}
        data-step={step}
        min={min}
        max={max}
        type="number"
      />

      <button
        className="btn-increment icon-plus"
        aria-label={stepUpHint}
        type="button"
        onClick={doStep.up}
        disabled={value >= max}
      >
        <AllyText>{stepUpHint}</AllyText>
      </button>
      {!valid && (
        <span className="validation-message">
          {errorMessage && `${errorMessage}`}
        </span>
      )}
    </Wrapper>
  );
};

export default styled(NumberInput,)``;

NumberInput.propTypes = {
  value        : PropTypes.number,
  min          : PropTypes.number,
  max          : PropTypes.number,
  step         : PropTypes.number,
  stepUpHint   : PropTypes.string,
  stepDownHint : PropTypes.string,
  errorMessage : PropTypes.string,
  onChange     : PropTypes.func,
};

NumberInput.defaultProps = {
  value        : 0,
  min          : 0,
  max          : 100,
  step         : 1,
  stepDownHint : "",
  stepUpHint   : "",
  errorMessage : "",
  onChange     : () => {
    window.console.log("changed",);
  },
};
