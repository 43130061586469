import React from "react";
import { get } from "lodash";
import styled, { css } from "styled-components";
import Fieldset from "../../../Fieldset";
import RadioInput from "../../../RadioInput";
import colors from "../../../../styles/colors";

const ActiveMixin = css`
  .text,
  .description {
    color: ${colors.brand1};
  }
`;

const Wrapper = styled.div`
  height: 75px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  .text {
    font-family: RC TYPE, Roboto, Avenir, Helvetica, Arial, sans-serif;
    font-weight: 500;
  }
  .description {
    font-weight: 300;
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      ${ActiveMixin}
    `}

  &:hover {
    ${ActiveMixin}
  }
`;

export default ({ data, className, value, doAnswer }) => {
  const ItemComponent = (props) => (
    // eslint-disable-next-line react/destructuring-assignment
    <Wrapper isSelected={props.isSelected}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      <div className="text">{get(data, "getLabelFromOption")(props.value)}</div>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      <div className="description">
        {get(data, "getDescriptionFromOption")(props.value)}
      </div>
    </Wrapper>
  );

  return (
    <div className={className}>
      <div className="question__inner">
        <Fieldset label={get(data, "label")}>
          <RadioInput
            options={get(data, "options")}
            value={value}
            onChange={doAnswer}
            ItemComponent={ItemComponent}
          />
        </Fieldset>
      </div>
    </div>
  );
};
