import React from "react";
import PropTypes from "prop-types";
import styled, { css, } from "styled-components";

const Wrapper = styled.fieldset`
  font-family: RC TYPE, Roboto, Avenir, Helvetica, Arial, sans-serif;
  color: #666;
  padding: 0;
  border-style: solid;
  border-width: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;

  ${({ label, },) => label
    && css`
      legend {
        padding-left: 0;
        text-indent: 1px;
        white-space: nowrap;
        transition: all 0.2s ease;
        font-size: 16px;
        display: inline-block;
        line-height: 24px;
        font-weight: 500;
      }
    `}
`;

const Fieldset = ({ label, children, className, },) => (
  <Wrapper className={className} label={label}>
    {label && <legend>{label}</legend>}
    {children}
  </Wrapper>
);

export default styled(Fieldset,)``;

Fieldset.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node,),
    PropTypes.node,
  ],),

  label: PropTypes.string,
};

Fieldset.defaultProps = {
  label    : "",
  children : null,
};
