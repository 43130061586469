import styled, { css } from "styled-components";
import colors from "../../styles/colors";
import sprite from "../../assets/royal-canin.sprite--iconography--xs.svg";

const Wrapper = styled.div`
  font-family: RC TYPE, Roboto, Avenir, Helvetica, Arial, sans-serif;
  display: inline-block;

  .label {
    padding-left: 0;
    text-indent: 1px;
    white-space: nowrap;
    transition: all 0.2s ease;
    font-size: 16px;
    display: inline-block;
    line-height: 24px;
    color: ${({ hasError }) => (hasError ? colors.brand1 : colors.font)};
    font-weight: 500;
    transform: translateY(2.3rem);
    pointer-events: none;
  }

  .inner {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    color: ${colors.font};
    font-weight: bold;
    border-bottom-width: 1px;
    border-bottom-color: ${({ hasError }) =>
      hasError ? colors.brand1 : "#808285"};
    border-bottom-style: solid;

    .search-icon {
      position: absolute;
      width: 24px;
      height: 48px;
      display: flex;
      align-items: center;

      .inner {
        width: 24px;
        height: 24px;
        background: url(${sprite}) 81% 83.71%;
        background-size: 345px;
      }
    }

    input {
      display: block;
      width: 100%;
      margin-bottom: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: transparent;
      border: ${colors.font};
      color: ${({ hasError }) => (hasError ? colors.brand1 : colors.font)};
      line-height: 46px;
      font-size: 16px;
      font-weight: 300;
    }
    .suffix {
      display: flex;
      align-items: center;
      padding-left: 4px;
      color: ${({ hasError }) => (hasError ? colors.brand1 : colors.font)};
      font-weight: 500;
    }
  }

  .error-message {
    font-size: 12px;
    color: ${colors.brand1};
  }

  ${({ hasValue }) =>
    hasValue &&
    css`
      .label {
        transform: none;
      }
    `}

  ${({ isSearch }) =>
    isSearch &&
    css`
      .inner {
        input {
          padding-left: 28px;
        }
      }
    `}

  ${({ isSearch, hasValue }) =>
    isSearch &&
    !hasValue &&
    css`
      .label {
        padding-left: 28px;
      }
    `}

  ${({ isBlock }) =>
    isBlock &&
    css`
      display: block;
      width: 100%;
    `}
`;

export default Wrapper;
