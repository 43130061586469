import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
// import ReactRouter from "react-router-dom";

const bindReactComponent = (
  Component,
  domElement,
  props = {},
  hasRecoil = true,
  afterBind
) => {
  if (hasRecoil) {
    ReactDOM.render(
      // <ReactRouter>
      <RecoilRoot>
        <Component {...props} />
      </RecoilRoot>,
      // </ReactRouter>,
      domElement,
      typeof afterBind === "function" ? afterBind : undefined
    );
  } else {
    ReactDOM.render(
      <Component {...props} />,
      domElement,
      typeof afterBind === "function" ? afterBind : undefined
    );
  }
};

export default bindReactComponent;
