import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { StyledItemWrapper, StyledLabel, StyledBCS } from "./styled";

import CatUnderWeight from "../../res/body-condition/cat/underweight.png";
import CatIdeal from "../../res/body-condition/cat/ideal.png";
import CatOverWeight from "../../res/body-condition/cat/overweight.png";

import DogUnderWeight from "../../res/body-condition/dog/underweight.png";
import DogIdeal from "../../res/body-condition/dog/ideal.png";
import DogOverWeight from "../../res/body-condition/dog/overweight.png";

export default ({ data, type, doAnswer, value }) => {
  const [bcs, setBCS] = useState(value);

  useEffect(() => {
    doAnswer(bcs);
  }, [bcs]);

  const ItemComponent = ({ value }) => {
    const getLabel = () => get(data, "getLabelFromValue")(value);
    const getDescription = () => get(data, "getDescriptionFromValue")(value);
    const getImage = () => {
      const image = get(data, "getImagePathFromValue")(value);

      if (image) return image;

      if (type === "cat") {
        switch (value) {
          case "3":
            return CatUnderWeight;
          case "5":
            return CatIdeal;
          default:
            return CatOverWeight;
        }
      } else {
        switch (value) {
          case "3":
            return DogUnderWeight;
          case "5":
            return DogIdeal;
          default:
            return DogOverWeight;
        }
      }
    };
    const getImageAlt = () => get(data, "getImageAltFromValue")(value);

    return (
      <StyledItemWrapper>
        <div className='image'>
          <img src={getImage()} alt={getImageAlt()} />
        </div>
        <div className='item-component__text'>
          <div className='item-component__title'>{getLabel()}</div>
          <div className='item-component__description'>{getDescription()}</div>
        </div>
      </StyledItemWrapper>
    );
  };
  return (
    <>
      <StyledLabel>{get(data, "title")}</StyledLabel>
      <StyledBCS
        className='situation-radio-input'
        value={bcs}
        onChange={setBCS}
        options={get(data, "options")}
        ItemComponent={ItemComponent}
      />
    </>
  );
};
