class EventEmitter {
    constructor() {
        this.events = {};
    }

    emit(eventName, data) {
        const events = this.events[eventName];
        if (events) {
            events.forEach((fn) => {
                fn.call(null, data);
            });
        }
    }

    subscribe(eventName, fn) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(fn);
    }

    unsubscribe(eventName, fn) {
        this.events[eventName] = this.events[eventName].filter(eventFn => fn !== eventFn);
    }
}

const eventHub = new EventEmitter();

export default eventHub;
