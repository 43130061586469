import EventEmitter from '$Foundation/src/js/EventEmitter';

export default class BaseCore {
    constructor() {
        this.componentClasses = [];
        this.componentInstances = [];
    }

    init() {
        EventEmitter.subscribe('content:updated', (container) => {
            this.initComponents(container);
        });
        EventEmitter.subscribe('content:willRemove', (container) => {
            this.destroyComponents(container);
        });
        this.initComponents();
    }

    initComponents(container) {
        this.componentClasses.forEach((ComponentClass) => {
            this.initComponent(container, ComponentClass);
        });
    }

    initComponent(containerEl, ComponentClass) {
        const container = containerEl || document;
        const componentName = ComponentClass.tagName;

        if (typeof componentName !== 'string') {
            throw new Error(' tagName is empty', ComponentClass);
        }

        const componentNodes = [
            ...container.querySelectorAll(`[data-component~="${componentName}"]`)
        ];

        componentNodes.forEach((componentNode) => {
            if (!componentNode.components) {
                componentNode.components = [];
            }

            let allowInit = true;

            componentNode.components.forEach((existingInstance) => {
                if (
                    existingInstance.constructor.tagName === componentName &&
                    existingInstance.isInited
                ) {
                    allowInit = false;
                }
            });

            if (allowInit) {
                const componentInstance = new ComponentClass(componentNode);

                componentNode.components.push(componentInstance);
                this.componentInstances.push(componentInstance);
            }
        });
    }

    destroyComponents(container) {
        this.componentInstances = this.componentInstances.filter((instance) => {
            if (instance.el === container || container.contains(instance.el)) {
                instance.destroy();
                if (instance.el) {
                    instance.el.components = instance.el.components.filter(i => i !== instance);
                }

                return false;
            }
            return true;
        });
    }
}
