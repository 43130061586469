import styled from "styled-components";
import RadioInput from "../../../RadioInput";

const StyledItemWrapper = styled.div`
  display: flex;
  padding: 15px 10px;
  text-align: left;

  .item-component__text {
    margin-left: 25px;
  }

  .item-component__title {
    font-size: 16px;
    font-weight: 400;
    color: #444;
    margin-bottom: 10px;
  }
  .item-component__description {
    color: #666;
  }
`;

const StyledLabel = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #666;
  margin-bottom: 24px;
`;

const StyledBCS = styled(RadioInput)`
  margin-bottom: 40px;
  width: 100%;
  ul {
    flex-direction: column;
    gap: 24px;
    li {
      width: 100%;
      &.selected,
      &:hover {
        .item-component__title {
          color: #e2001a;
        }
      }
    }
    @media (min-width: 768px) {
      flex-direction: row;
      gap: 60px;
    }
  }
`;

export { StyledItemWrapper, StyledLabel, StyledBCS };
