import React, { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import styled, { css } from "styled-components";
import colors from "../../styles/colors";

const activeItemMixin = css`
  button {
    border-color: ${colors.brand1};
  }
  ${({ isVertical, gap }) =>
    !isVertical &&
    !gap &&
    css`
      // & + li {
      //   button {
      //     border-left-color: ${colors.red};
      //   }
      // }
    `}
`;

const Wrapper = styled.div`
  font-family: RC TYPE, Roboto, Avenir, Helvetica, Arial, sans-serif;
  label {
    color: ${colors.font};
    font-weight: 500;
    font-size: 16px;
  }
  ul {
    display: flex;
    flex-direction: ${({ isVertical }) => (isVertical ? "column" : "row")};
    list-style: none;
    ${({ gap }) =>
      !!gap &&
      css`
        gap: ${gap};
      `};
    margin: 0;
    padding: 0;

    li {
      height: auto;
      ${({ isVertical, options }) =>
        !isVertical &&
        css`
          width: ${100 / options.length}%;
        `}

      button {
        cursor: pointer;
        background: transparent;
        width: 100%;
        height: 100%;
        border: ${({ borderWidth }) => borderWidth} solid
          ${({ inactiveBorderColor }) => inactiveBorderColor};
        transition: all 150ms ease-out;
        color: #666;
        border-radius: 4px;
      }

      &.selected {
        ${activeItemMixin}
      }
      &:hover {
        ${activeItemMixin}
      }

      ${({ isVertical }) =>
        !isVertical &&
        css`
          &:first-of-type {
            button {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
          }
          &:last-of-type {
            button {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }

          ${({ gap }) =>
            !gap &&
            css`
              //button {
              //  border-radius: 0;
              //}
              //
              //&:not(:last-of-type) {
              //  button {
              //    border-right-width: 0;
              //  }
              //}
            `}
        `})
    }

    &.free-style {
      li {
        button {
          border-width: 0 !important;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
`;

const ItemComponentWrapper = styled.div`
  height: 30px;
`;

const RadioInput = ({
  id = uuidv4(),
  label,
  value,
  options,
  onChange,
  isVertical = true,
  inactiveBorderColor = "#D7D7D7",
  activeBorderColor = colors.brand1,
  borderWidth = "1px",
  className,
  gap,
  ItemComponent,
}) => {
  const ul = useRef(null);

  const buttonHandlers = {
    click: (v, _e) => {
      onChange(v);
    },
  };

  return (
    <Wrapper
      id={id}
      gap={gap}
      options={options}
      isVertical={isVertical}
      inactiveBorderColor={inactiveBorderColor}
      activeBorderColor={activeBorderColor}
      borderWidth={borderWidth}
      className={className}
    >
      {!!label && <label htmlFor={`label-${id}`}>{label}</label>}
      <ul ref={ul} role="radiogroup" aria-labelledby={`label-${id}`}>
        {options.map((option) => (
          <li
            key={option}
            className={classNames(value === option && "selected")}
          >
            <button
              type="button"
              role="radio"
              aria-checked={value === option.value}
              onClick={(e) => buttonHandlers.click(option, e)}
              tabIndex={0}
            >
              <ItemComponent value={option} isSelected={value === option} />
            </button>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default styled(RadioInput)``;

RadioInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  options: PropTypes.arrayOf(Object),
  onChange: PropTypes.func.isRequired,
  isVertical: PropTypes.bool,
  inactiveBorderColor: PropTypes.string,
  activeBorderColor: PropTypes.string,
  borderWidth: PropTypes.string,
  gap: PropTypes.string,
  ItemComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

RadioInput.defaultProps = {
  id: uuidv4(),
  label: "",
  value: "",
  options: [],
  isVertical: false,
  inactiveBorderColor: "#D7D7D7",
  activeBorderColor: colors.brand1,
  borderWidth: "1px",
  gap: "",
  ItemComponent: ({ value }) => (
    <ItemComponentWrapper>{value}</ItemComponentWrapper>
  ),
};
