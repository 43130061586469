import { useEffect, } from "react";

export const useClickOutside = (ref, action,) => {
  useEffect(() => {
    const mousedownHandler = (event,) => {
      if (ref.current && !ref.current.contains(event.target,)) {
        action();
      }
    };

    document.addEventListener("mousedown", mousedownHandler, true,);

    return () => {
      document.removeEventListener("mousedown", mousedownHandler, true,);
    };
  }, [
    ref,
    action,
  ],);
};
