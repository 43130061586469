import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styled, { css, } from "styled-components";
import colors from "../../styles/colors";
import spriteBrand2 from "../../assets/royal-canin.sprite-brand2-xs.svg";
import spriteBrand3 from "../../assets/royal-canin.sprite-brand3-xs.svg";

const Wrapper = styled.button`
  border-radius: 999px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 500;
  padding: 0.6rem 2rem;
  display: inline-block;
  position: relative;
  border: 2px solid transparent;
  font-family: RC TYPE, Roboto, Avenir, Helvetica, Arial, sans-serif;

  ${({ isBlock, },) => isBlock
    && css`
      width: 100%;
    `};

  ${({ variant, },) => {
    switch (variant) {
      case "primary":
        return css`
          background-color: ${colors.brand1};
          color: #fff;
          border: 2px solid transparent;
          &:disabled {
            background-color: ${colors.grey};
          }
          &::after {
            background-image: url(${spriteBrand2});
          }
        `;
      case "secondary":
        return css`
          background-color: transparent;
          border: 2px solid ${colors.brand1};
          color: ${colors.brand1};
          &:disabled {
            color: ${colors.grey};
          }
          &::after {
            background-image: url(${spriteBrand3});
          }
        `;
      default:
        return css``;
    }
  }};

  &:hover:not(:disabled):after {
    right: 0.25rem;
  }

  &::after {
    content: "";
    display: inline-block;
    background-position: 63.64% 85.71%;
    background-repeat: no-repeat;
    width: 24px;
    height: 32px;
    overflow: hidden;
    transform-origin: 50% 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    right: -2.5rem;
  }
  &:disabled {
    border-color: ${colors.grey};
    cursor: not-allowed;
  }
  & + & {
    margin-left: 0.25rem;
  }

  //@media (min-width: 769px) {
  //  &:hover:not(:disabled):after {
  //    right: 0.5rem;
  //  }
  //  padding: 0.6rem 2rem;
  //  font-size: 16px;
  //  & + & {
  //    margin-left: 0.5rem;
  //  }
  //}
`;

const Button = ({
  label,
  variant,
  isDisabled,
  isBlock,
  onClick,
  className = undefined,
  ...props
},) => (
  <Wrapper
    isBlock={isBlock}
    type="button"
    disabled={isDisabled}
    onClick={typeof onClick === "function" ? onClick : () => {}}
    variant={variant}
    className={classNames(` btn-${variant}`, !!className && className,)}
    {...props}
  >
    {label}
  </Wrapper>
);

export default styled(Button,)``;

Button.propTypes = {
  label   : PropTypes.string,
  variant : PropTypes.oneOf([
    "primary",
    "secondary",
  ],),
  isDisabled : PropTypes.bool,
  isBlock    : PropTypes.bool,
  onClick    : PropTypes.func,
};

Button.defaultProps = {
  label      : "",
  variant    : "primary",
  isDisabled : false,
  isBlock    : false,
  onClick    : undefined,
};
