const formatOptionString = (str, filter) => {
  const getAllIndexes = (arr, val) => {
    const indexes = [];
    let i = -1;
    // eslint-disable-next-line no-cond-assign
    while ((i = arr.indexOf(val, i + 1)) !== -1) {
      indexes.push(i);
    }
    return indexes;
  };
  const insertAtIndex = (s, insert, index) => {
    return s.slice(0, index) + insert + s.slice(index);
  };

  const indexes = getAllIndexes(str.toLowerCase(), filter.toLowerCase());
  for (let i = 0; i < indexes.length; i++) {
    const index = indexes[i];
    // eslint-disable-next-line no-param-reassign
    str = insertAtIndex(str, "<strong>", index);
    // eslint-disable-next-line no-param-reassign
    str = insertAtIndex(str, "</strong>", index + 8 + filter.length);
    for (let k = i + 1; k < indexes.length; k++) {
      indexes[k] += 8 + 9 + filter.length;
    }
  }

  return str;
};

export default formatOptionString;