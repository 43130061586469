const CheckPfURLParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const pfState = {
    breed: urlParams.get("pet_breedCode") || undefined,
    size: urlParams.get("pet_size") || undefined,
    gender: urlParams.get("pet_genderCode") || undefined,
    age: urlParams.get("pet_age") || undefined,
    weight: urlParams.get("pet_weight") || undefined,
    isNeutered: urlParams.get("pet_neutered") || undefined,
    activity: urlParams.get("pet_petActivityCode") || undefined,
    bcs: urlParams.get("pet_bcs") || undefined,
  };

  if (
    pfState.breed &&
    pfState.size &&
    pfState.gender &&
    pfState.age &&
    pfState.weight &&
    pfState.isNeutered &&
    pfState.activity &&
    pfState.bcs
  ) {
    return pfState;
  } else {
    return undefined;
  }
};

export default CheckPfURLParams;
